<template>
  <div class="py-5 text-center">
    <h1>Login</h1>
    <br />
    <b-img
      :src="logo"
      blank-color="#ccc"
      width="150"
      alt="dosetech-logo"
    ></b-img>
    <br />
    <br />
    <br />
    <b-row class="text-left justify-content-center mb-3">
      <b-col md="5">
        <div role="group">
          <label for="input-email">Email: </label>
          <b-form-input
            id="input-email"
            v-model="form.email"
            :state="emailState"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Enter your email"
            trim
            name="email"
          ></b-form-input>

          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Please enter your email address (e.g. email@example.com)
          </b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>
    <b-row class="text-left justify-content-center mb-3">
      <b-col md="5">
        <div role="group">
          <label for="input-password">Password:</label>
          <b-form-input
            id="input-password"
            v-model="form.password"
            :state="passwordState"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Enter your password"
            type="password"
            trim
          ></b-form-input>

          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Enter at least 6 letters
          </b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>
    <!-- :disabled="isLoading"  -->
    <b-button variant="primary" class="mb-2" @click="submit">LOGIN</b-button>
    <br />
    <br />
    <p class="text-danger mt-3" v-if="msg">{{ msg }}</p>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import logo from "@/assets/dosetech-logo.png";

export default {
  validations() {
    return {
      form: {
        email: { required, email },
        password: {
          required,
          minLength: minLength(6),
        },
      },
    };
  },
  data() {
    return {
      logo: logo,
      form: {
        email: "",
        password: "",
      },
      isLoading: false,
      msg: "",
    };
  },
  computed: {
    emailState() {
      if (this.$v.form.email.$error) return !this.$v.form.email.$error;
      return this.form.email.length > 0 ? !this.$v.form.email.$invalid : null;
    },
    passwordState() {
      if (this.$v.form.password.$error) return !this.$v.form.password.$error;
      return this.form.password.length > 0
        ? !this.$v.form.password.$invalid
        : null;
    },
  },
  methods: {
    submit: async function () {
      this.msg = "";
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      await this.axios
        .post(`${this.$baseUrl}/login`, this.form)
        .then(async (res) => {
          if (res.result == 1) {
            await localStorage.setItem(
              "dt_access_token",
              `${res.detail.token}`
            );
            this.$router.push({ name: "index" });
          } else {
            this.msg = res.message;
            await localStorage.removeItem("dt_shopee_access_token");
          }
        })
        .catch((err) => {
          this.msg = err.error_message;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.fz-14 {
  font-size: 14px !important;
}
</style>